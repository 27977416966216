
import { Component, Watch, Prop, Vue, Ref } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { FormInput, FormYesNo, FormMoney } from "@/components/forms";

interface ComissionPayload {
    comission: null | number | "";
    type: null | "fixed" | "relative";
    vat_included: boolean;
}

@Component({
    components: {
        ValidationProvider,
        FormInput,
        FormMoney,
        FormYesNo,
    },
})
export default class FormComission extends Vue {
    @Prop() value!: boolean;

    @Prop() name!: string;

    @Prop() label!: string;

    @Prop({ default: false }) disabled?: boolean;

    @Prop() rules?: string | object;

    @Ref() provider!: typeof ValidationProvider;

    local_relative_commission: null | number | "" = null;
    local_relative_vat: boolean = false;
    local_fixed_commission: null | number | "" = null;
    local_fixed_vat: boolean = false;

    localValue: ComissionPayload = {
        comission: null,
        type: null,
        vat_included: true,
    };

    get isRelative() {
        return this.localValue.type === "relative";
    }

    get isFixed() {
        return this.localValue.type === "fixed";
    }

    handleInput() {
        this.validate();
        this.$emit("input", this.localValue);
    }

    setInvalid() {
        if (!this.provider) {
            return;
        }

        this.provider.setFlags({
            untouched: true,
            touched: false,
            dirty: false,
            pristine: false,
            valid: false,
            invalid: true,
            validated: false,
            pending: true,
            required: true,
            changed: false,
            passed: false,
            failed: true,
        });
    }

    validate() {
        const success = !!this.localValue.comission && !!this.localValue.type;
        if (!success) {
            this.provider.setErrors(["Ereloon is verplicht"]);
        } else {
            this.provider.setErrors([]);
        }
        this.provider.setFlags({
            untouched: false,
            touched: true,
            dirty: true,
            pristine: false,
            valid: success,
            invalid: !success,
            validated: true,
            pending: false,
            required: true,
            changed: true,
            passed: success,
            failed: !success,
        });
    }

    handleRelativeCommissionChanged() {
        this.local_fixed_commission = null;
        this.localValue.type = "relative";
        this.localValue.comission = this.local_relative_commission;
        this.localValue.vat_included = this.local_relative_vat;
        this.handleInput();
    }
    handleFixedCommissionChanged() {
        this.local_relative_commission = null;
        this.localValue.type = "fixed";
        this.localValue.comission = this.local_fixed_commission;
        this.localValue.vat_included = this.local_fixed_vat;
        this.handleInput();
    }

    @Watch("value", { immediate: true })
    private valueChanged(newValue: ComissionPayload) {
        if (newValue) {
            this.localValue = newValue;
            if (newValue.type === "fixed") {
                this.local_relative_commission = null;
                this.local_fixed_commission = newValue.comission;
                this.local_fixed_vat = newValue.vat_included;
            } else {
                this.local_fixed_commission = null;
                this.local_relative_commission = newValue.comission;
                this.local_relative_vat = newValue.vat_included;
            }
        } else {
            this.localValue = {
                comission: null,
                type: null,
                vat_included: true,
            };
            this.setInvalid();
        }
    }
}
